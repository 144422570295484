import { Component } from '@angular/core';
import { Router, RouterOutlet} from '@angular/router';
import { AlertController, MenuController, ToastController } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import OneSignal from 'onesignal-cordova-plugin';
import { Platform } from '@ionic/angular';
import { HomePage } from './pages/home/home.page';
import { ListingsPage } from './pages/listings/listings.page';
import { GlobalService } from './services/global.service';
import { UserCredential, UserService } from 'src/app/services/user.service';
import { ReservationsService } from './services/crud/reservations.service';
// import { AppUpdate } from '@capawesome/capacitor-app-update';
import { register } from 'swiper/element/bundle';
register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  userCredential: UserCredential;
  totalOperatorForApproval = 0;
  constructor(
    private router: Router,
    private menu: MenuController,
    private platform: Platform,
    private globalService: GlobalService,
    private userService: UserService,
    public alertController: AlertController,
    private reservationService:  ReservationsService,
    private toastController: ToastController
  ) {

    this.authenticateUser();
    // this.initializeApp();

    if (this.platform.is('mobile') && this.platform.is('cordova')){

      const statusBarBackgroundColorOptions = {
        color : '#C22868'
      };

      StatusBar.setStyle({ style: Style.Dark });
      StatusBar.setBackgroundColor(statusBarBackgroundColorOptions);
      StatusBar.setOverlaysWebView({ overlay: true });

      // alert(this.getCurrentAppVersion() + ' vs ' + this.getAvailableAppVersion);
       this.oneSignalInit();
      
    }
    

  }

  // getCurrentAppVersion = async () => {
  //   let result = await AppUpdate.getAppUpdateInfo();
  //   return result.currentVersion;
  // };
  
  // getAvailableAppVersion = async () => {
  //   let result = await AppUpdate.getAppUpdateInfo();
  //   return result.availableVersion;
  // };


  redirectPage(page){
    this.router.navigate(['/' + page]);
    this.menu.close('custom');
  }

  openFirst() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  // Call this function when your app starts
  oneSignalInit(){



    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);
    OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId('2bb211c7-4712-4b1a-bcf6-6f80e90eb401');
    OneSignal.setNotificationOpenedHandler((jsonData: any) => {
        // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        // alert(JSON.stringify(jsonData.notification.additionalData));

        if (jsonData.notification.additionalData.page && jsonData.notification.additionalData.reservation_id){
          const navigationExtras = {
            queryParams: {
              id : jsonData.notification.additionalData.reservation_id
            }
          };
          this.router.navigate(['/' + jsonData.notification.additionalData.page], navigationExtras);
        }

        if (jsonData.notification.additionalData.page && jsonData.notification.additionalData.page === 'message'){
          const navigationExtras = {
            queryParams: {
              id : jsonData.notification.additionalData.id
            }
          };
          this.router.navigate(['/' + jsonData.notification.additionalData.page], navigationExtras);
        }
        if (jsonData.notification.additionalData.page && jsonData.notification.additionalData.page === 'operator-message'){
          const navigationExtras = {
            queryParams: {
              vehicleID : jsonData.notification.additionalData.vehicleID,
              customerID : jsonData.notification.additionalData.customerID
            }
          };
          this.router.navigate(['/' + jsonData.notification.additionalData.page], navigationExtras);
        }

    });

    // iOS - Prompts the user for notification permissions.
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
        console.log('User accepted notifications: ' + accepted);
    });


    const userID = localStorage.getItem('account_user_id');
    if (userID){
      OneSignal.setExternalUserId(userID);
    }
    

    // const device = OneSignal.getDeviceState( stateChanges => {
    //   console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
    // });

    // alert(OneSignal.getUserId);

  }

  authenticateUser(){
    if (localStorage.getItem('account_authenticated') && localStorage.getItem('account_authenticated') === 'yes'){

      // Try to login
      this.loginNow(localStorage.getItem('account_email_address'),localStorage.getItem('account_password'));

    }
  }

  loginNow(emailAddress,password){

    this.userCredential = {
      emailAddress,
      password
    };
    this.userService.login(this.userCredential)
      .subscribe((response) => {
        console.log('response',response);

        
        this.globalService.favorites = response.favorites;
        console.log('this.globalService.favorites',this.globalService.favorites);

        if (response.user_exist){
          localStorage.setItem('account_user_id',response.user_id);


          
          if (this.platform.is('mobile') && this.platform.is('cordova')){
            // OneSignal.getDeviceState((stateChanges) => {
            //   // alert(JSON.stringify(stateChanges));
            //   if (stateChanges.subscribed){
            //     OneSignal.setExternalUserId(response.user_id);
            //   }
              
            // });
          }
          

          localStorage.setItem('account_email_address',emailAddress);
          localStorage.setItem('account_password',password);
          localStorage.setItem('account_authenticated','yes');

          this.globalService.accountUserID = response.user_id;
          this.globalService.accountAuthenticated = true;
          this.globalService.accountEmailAddress = emailAddress;

          this.globalService.accountFirstName = response.first_name;
          this.globalService.accountLastName = response.last_name;
          this.globalService.accountAddress = response.address;
          this.globalService.accountCity = response.city;
          this.globalService.accountProvince = response.province;
          this.globalService.accountZip = response.zip;

          this.globalService.accountWithOperatorApplication = response.applied_operator;


          localStorage.setItem('operator_location_selected_latitude',response.operator_latitude);
          localStorage.setItem('operator_location_selected_longitude',response.operator_longitude);


          this.initTotalCustomerForPayment();
          
          setInterval(() => {
            this.initTotalCustomerForPayment();
          },45000);

          if (response.is_operator){
            this.globalService.accountIsOperator = true;
            localStorage.setItem('account_is_operator','yes');

            this.initTotalOperatorApproval();
            setInterval(() => {
              this.initTotalOperatorApproval();
            },35000);
          }else{
            this.globalService.accountIsOperator = false;
            localStorage.setItem('account_is_operator','no');

            
          }

        }

        
        
        this.globalService.currentAppPlatform = response.current_app_platform;
        this.globalService.currentAppVersion = response.current_app_version;
        this.globalService.latestAppVersion = response.latest_app_version;

        if (response.updated_required){
          this.globalService.requiredAppLink = response.updated_required_link;
          this.router.navigate(['/update-required']);
        }

    });
  }


  async logout(){

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm Logout',
      message: '',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Continue',
          id: 'confirm-button',
          handler: () => {

            localStorage.removeItem('account_user_id');
            localStorage.removeItem('account_email_address');
            localStorage.removeItem('account_password');
            localStorage.removeItem('account_authenticated');
            this.globalService.accountAuthenticated = false;
            this.globalService.accountEmailAddress = '';
            this.globalService.accountFirstName = '';
            this.globalService.accountLastName = '';
            this.globalService.accountAddress = '';
            this.globalService.accountCity = '';
            this.globalService.accountProvince = '';
            this.globalService.accountZip = '';
            this.router.navigate(['/']);
            this.menu.enable(false, 'custom');
            this.menu.close('custom');

          }
        }
      ]
    });

    await alert.present();

    // browser.executeScript(...);

    // browser.insertCSS(...);
    // browser.on('loadstop').subscribe(event => {
    //   browser.insertCSS({ code: 'body{color: red;' });
    // });

    // browser.close();
  }


  initTotalOperatorApproval(){
    const userID = localStorage.getItem('account_user_id');
    if (userID){
      this.reservationService.getReservationsStatsByOperatorID(userID).subscribe((response) => {
        // this.globalService.totalOperatorForApproval = data.length;
        // alert(this.globalService.totalOperatorForApproval);

        this.globalService.totalOperatorForApproval = 0;
        console.log('response',response);
        if (response['pending_operator_approval']){
          this.globalService.totalOperatorForApproval+=response['pending_operator_approval'];
        }
        if (response['operator_approved']){
          this.globalService.totalOperatorForApproval+=response['operator_approved'];
        }
        if (response['pending_payment']){
          this.globalService.totalOperatorForApproval+=response['pending_payment'];
        }

        if (response['pending_payment']){
          this.globalService.totalOperatorForApproval+=response['pending_payment'];
        }
        if (response['reserved']){
          this.globalService.totalOperatorForApproval+=response['reserved'];
        }
        if (response['delivered']){
          this.globalService.totalOperatorForApproval+=response['reserved'];
        }

        console.log('this.globalService.totalOperatorForApproval',this.globalService.totalOperatorForApproval);

        // Display only for active orders
        if (this.globalService.totalOperatorForApproval > 0 ){
          this.updateSyncOperator();
        }

      });
    }
  }
  initTotalCustomerForPayment(){
    console.log('initTotalCustomerForPayment');
    const userID = localStorage.getItem('account_user_id');
    if (userID){
      // alert( this.globalService.totalCustomerForPayment);
      // console.log('this.reservationService.getReservationsByCustomerID');
      // this.reservationService.getReservationsByCustomerID(userID,'operator_approved').subscribe((data) => {

      //   this.globalService.totalCustomerForPayment = data.length;
      //   console.log(' this.globalService.totalCustomerForPayment',data.length);

      //   this.reservationService.getReservationsByCustomerID(userID,'pending_payment').subscribe((data) => {
      //     this.globalService.totalCustomerForPayment+= data.length;
      //     console.log(' this.globalService.totalCustomerForPayment',data.length);
      //   });

      // });
      this.globalService.totalCustomerForPayment = 0;
      this.reservationService.getReservationsStatsByCustomerID(userID).subscribe( response => {
        console.log('response',response);
        if (response['pending_payment']){
          this.globalService.totalCustomerForPayment+=response['pending_payment'];
        }
        if (response['operator_approved']){
          this.globalService.totalCustomerForPayment+=response['operator_approved'];
        }
        if (response['pending_operator_approval']){
          this.globalService.totalCustomerForPayment+=response['pending_operator_approval'];
        }
        console.log('this.globalService.totalCustomerForPayment',this.globalService.totalCustomerForPayment);

        // Display only for active orders
        if (this.globalService.totalCustomerForPayment > 0 ){
          this.updateSync();
        }
        
      });
      
    }
  }

  async updateSync(){
    const toast = await this.toastController.create({
      message: 'Hi! Update:  Reservations data are synced.',
      duration: 1500,
      icon: 'information-circle',
      position: 'top',
      color: 'primary'
    });
    toast.present();
  }
  async updateSyncOperator(){
    const toast = await this.toastController.create({
      message: 'For Operator: Reservations data are synced.',
      duration: 1500,
      icon: 'information-circle',
      position: 'top',
      color: 'primary'
    });
    toast.present();
  }
}
