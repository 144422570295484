import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'listing',
    loadChildren: () => import('./pages/listing/listing.module').then( m => m.ListingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/account/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/account/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/account/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'apply-operator',
    loadChildren: () => import('./pages/apply-operator/apply-operator.module').then( m => m.ApplyOperatorPageModule)
  },
  {
    path: 'operator-dashboard',
    loadChildren: () => import('./pages/operator-dashboard/operator-dashboard.module').then( m => m.OperatorDashboardPageModule)
  },
  {
    path: 'operator-profile',
    loadChildren: () => import('./pages/operator-profile/operator-profile.module').then( m => m.OperatorProfilePageModule)
  },
  {
    path: 'operator-vehicles',
    loadChildren: () => import('./pages/operator-vehicles/operator-vehicles.module').then( m => m.OperatorVehiclesPageModule)
  },
  {
    path: 'operator-addresses',
    loadChildren: () => import('./pages/operator-addresses/operator-addresses.module').then( m => m.OperatorAddressesPageModule)
  },
  {
    path: 'customer-dashboard',
    loadChildren: () => import('./pages/customer-dashboard/customer-dashboard.module').then( m => m.CustomerDashboardPageModule)
  },
  {
    path: 'customer-profile',
    loadChildren: () => import('./pages/customer-profile/customer-profile.module').then( m => m.CustomerProfilePageModule)
  },
  {
    path: 'customer-reservations',
    loadChildren: () => import('./pages/customer-reservations/customer-reservations.module').then( m => m.CustomerReservationsPageModule)
  },
  {
    path: 'operator-reservations',
    loadChildren: () => import('./pages/operator-reservations/operator-reservations.module').then( m => m.OperatorReservationsPageModule)
  },
  {
    path: 'operator-top-up',
    loadChildren: () => import('./pages/operator-top-up/operator-top-up.module').then( m => m.OperatorTopUpPageModule)
  },
  {
    path: 'operator-withdraw',
    loadChildren: () => import('./pages/operator-withdraw/operator-withdraw.module').then( m => m.OperatorWithdrawPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'listings',
    loadChildren: () => import('./pages/listings/listings.module').then( m => m.ListingsPageModule)
  },
  {
    path: 'customer-reservation',
    loadChildren: () => import('./pages/customer-reservation/customer-reservation.module').then( m => m.CustomerReservationPageModule)
  },
  {
    path: 'listing-reviews',
    loadChildren: () => import('./pages/listing-reviews/listing-reviews.module').then( m => m.ListingReviewsPageModule)
  },
  {
    path: 'listing-write-review',
    loadChildren: () => import('./pages/listing-write-review/listing-write-review.module').then( m => m.ListingWriteReviewPageModule)
  },
  {
    path: 'operator-vehicle',
    loadChildren: () => import('./pages/operator-vehicle/operator-vehicle.module').then( m => m.OperatorVehiclePageModule)
  },
  {
    path: 'operator-vehicle-add',
    loadChildren: () => import('./pages/operator-vehicle-add/operator-vehicle-add.module').then( m => m.OperatorVehicleAddPageModule)
  },
  {
    path: 'operator-fund-account',
    loadChildren: () => import('./pages/operator-fund-account/operator-fund-account.module').then( m => m.OperatorFundAccountPageModule)
  },
  {
    path: 'operator-fund-withdraw',
    loadChildren: () => import('./pages/operator-fund-withdraw/operator-fund-withdraw.module').then( m => m.OperatorFundWithdrawPageModule)
  },
  {
    path: 'geo-locator',
    loadChildren: () => import('./pages/geo-locator/geo-locator.module').then( m => m.GeoLocatorPageModule)
  },
  {
    path: 'manage-customer-reservations',
    loadChildren: () => import('./pages/manage-customer-reservations/manage-customer-reservations.module').then( m => m.ManageCustomerReservationsPageModule)
  },
  {
    path: 'manage-customer-reservation',
    loadChildren: () => import('./pages/manage-customer-reservation/manage-customer-reservation.module').then( m => m.ManageCustomerReservationPageModule)
  },
  {
    path: 'customer-reservations-status',
    loadChildren: () => import('./pages/customer-reservations-status/customer-reservations-status.module').then( m => m.CustomerReservationsStatusPageModule)
  },
  {
    path: 'manage-customer-reservations-status',
    loadChildren: () => import('./pages/manage-customer-reservations-status/manage-customer-reservations-status.module').then( m => m.ManageCustomerReservationsStatusPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'message',
    loadChildren: () => import('./pages/message/message.module').then( m => m.MessagePageModule)
  },
  {
    path: 'manage-customer-messages',
    loadChildren: () => import('./pages/manage-customer-messages/manage-customer-messages.module').then( m => m.ManageCustomerMessagesPageModule)
  },
  {
    path: 'operator-message',
    loadChildren: () => import('./pages/operator-message/operator-message.module').then( m => m.OperatorMessagePageModule)
  },
  {
    path: 'customer-messages',
    loadChildren: () => import('./pages/customer-messages/customer-messages.module').then( m => m.CustomerMessagesPageModule)
  },
  {
    path: 'update-required',
    loadChildren: () => import('./update-required/update-required.module').then( m => m.UpdateRequiredPageModule)
  },
  {
    path: 'operator/:id',
    loadChildren: () => import('./pages/operator/operator.module').then( m => m.OperatorPageModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then( m => m.FavoritesPageModule)
  },  {
    path: 'discount-codes',
    loadChildren: () => import('./discount-codes/discount-codes.module').then( m => m.DiscountCodesPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
