import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ReservationsService } from './crud/reservations.service';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  accountStatus = false;
  endpoint = 'https://werideph.com/wp-json/v1/';
  // endpoint = 'https://jsonplaceholder.typicode.com/';

  accountVerified = false;
  accountUserID = '';
  accountAuthenticated = false;
  accountEmailAddress = '';

  accountFirstName = '';
  accountLastName = '';
  accountAddress = '';
  accountProvince = '';
  accountCity = '';
  accountZip = '';

  accountCityOrMunicipality = '';
  accountIsOperator = false;
  accountWithOperatorApplication = false;

  lastPage = '';
  totalOperatorForApproval = 0;
  totalCustomerForPayment = 0;

  // vehicleUnvailableDates = [];

  requiredAppLink: string = '';
  currentAppPlatform: number ;
  currentAppVersion: number;
  latestAppVersion: number;

  favorites: any = [];

  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(
    private location: Location,
    private httpClient: HttpClient,
    private platform: Platform
  ) {
    this.platform.backButton.subscribeWithPriority(5, () => {
      this.location.back();
    });
  }

  getHomeSliders(): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + 'settings/home-sliders')
      .pipe(
        tap(_ => console.log(`Home Slider Fetched`)),
        catchError(this.handleError<any>(`Home Slider Fetched Error`))
      );
  }

  contactRequest(payload: any): Observable<any> {
    return this.httpClient.post<any>(this.endpoint + 'features/contact', JSON.stringify(payload), this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }


  goBack(){
    this.location.back();
  }

  replaceAll(text, search, replace) {
    return text.split(search).join(replace);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
