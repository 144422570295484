import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { Platform } from '@ionic/angular';



export class UserCredential {
  emailAddress: string;
  password: string;
}

export class User {
  emailAddress: string;
  contactNumber: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  address: string;
  city: string;
  province: string;
  zip: string;
  latitude: string;
  longitude: string;
  province_code: string;
  city_code: string;
  barangay_code: string;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  endpoint = '';

  // ** REQUIRED TO UPDATE 
  appPlatform: string = 'web';
  appVersion: number = .13;
  currentAndroidVersion: number = 0.13;
  currentIOSVersion: number = 0.13;


  updateRequired: boolean = false;
  updateRequiredLink: string = '';

  accessToken = '';

  userFavorites: any;
  user: any;

  userDiscountCodes: any;
  userDiscountCodeSelected = null;

  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private platform: Platform
  ){
    this.endpoint = this.globalService.endpoint;
  }

  login(userCredetial: any): Observable<any> {

    // Check Platform
    // if (this.platform.is('mobile')){
      
      if (this.platform.is('android')){
        this.appPlatform = 'android';
        this.appVersion = this.currentAndroidVersion;
      }

      if (this.platform.is('ios')){
        this.appPlatform = 'ios';
        this.appVersion = this.currentIOSVersion;
      }

    // }
    
    userCredetial.appPlatform = this.appPlatform;
    userCredetial.appVersion = this.appVersion;


    return this.httpClient.post<any>(this.endpoint + 'account/login', JSON.stringify(userCredetial), this.httpOptions)
      .pipe(
        tap(data => {
          console.log('user logged in', data);
          this.accessToken = data.access_token;
          this.userFavorites = data.favorites;
          this.user = data.user;
          // alert(this.accessToken);
          // this.updateRequired = data.update_required;
          // this.updateRequiredLink = data.update_required_link;
        }),
        catchError(this.handleError<any>('Error occured'))
      );
  }

  registerUser(data: any): Observable<any> {
    return this.httpClient.post<any>(this.endpoint + 'account/register', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }

  forgotUser(data: any): Observable<any> {
    return this.httpClient.post<any>(this.endpoint + 'account/forgot', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }
  updatePassword(data: any): Observable<any> {
    return this.httpClient.post<any>(this.endpoint + 'account/update', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }

  getUser(userID = ''): Observable<User> {
    return this.httpClient.get<User>(this.endpoint + 'account/' + userID + '?token=' + this.accessToken)
      .pipe(
        tap(_ => console.log(`user fetched: ${userID}`)),
        catchError(this.handleError<User>(`Get user id=${userID}`))
      );
  }
  

  getOperator(userID: number): Observable<User> {
    return this.httpClient.get<User>(this.endpoint + 'account-operator/' + userID)
      .pipe(
        tap(_ => console.log(`Operator fetched: ${userID}`)),
        catchError(this.handleError<User>(`Get Operator id=${userID}`))
      );
  }

  userFavorite(userID = '', vehicleID, status): Observable<any> {

    let param = 'user_id=' + userID + '&vehicle_id=' + vehicleID + '&status=' + status;
    return this.httpClient.get<any>(this.endpoint + 'account/favorite?token=' + this.accessToken + '&' + param)
      .pipe(
        tap(data => this.userFavorites = data),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }

  updateUser(userID, user: any): Observable<any> {
    return this.httpClient.put(this.endpoint + 'account/' + userID, JSON.stringify(user), this.httpOptions)
      .pipe(
        tap(_ => console.log(`user updated: ${userID}`)),
        catchError(this.handleError<any>('user vehicle'))
      );
  }

  deleteUser(userID): Observable<any> {
    return this.httpClient.delete(this.endpoint + 'account/' + userID, this.httpOptions)
      .pipe(
        tap(_ => console.log(`user deleted: ${userID}`)),
        catchError(this.handleError<User>('user vehicle'))
      );
  }

  applyAsOperator(details: FormData): Observable<any> {
    return this.httpClient.post<any>(this.endpoint + 'account/apply-as-operator',details)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }
  

  // OTP
  sendUserOTP(userID = ''): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + 'send-email-otp/' + userID + '?token=' + this.accessToken)
      .pipe(
        tap(_ => console.log(`user fetched: ${userID}`)),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }
  valiteUserOTP(userID = '',otp = ''): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + 'validate-email-otp/' + userID + '/' + otp + '?token=' + this.accessToken)
      .pipe(
        tap(_ => console.log(`user fetched: ${userID}`)),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }

  // OTP Phone
  sendUserPhoneNumberOTP(userID = ''): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + 'send-phone-number-otp/' + userID + '?token=' + this.accessToken)
      .pipe(
        tap(_ => console.log(`user fetched: ${userID}`)),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }
  valiteUserPhoneNumberOTP(userID = '',otp = ''): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + 'validate-phone-number-otp/' + userID + '/' + otp + '?token=' + this.accessToken)
      .pipe(
        tap(_ => console.log(`user fetched: ${userID}`)),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }

  submitIDWithSelfie(details: FormData): Observable<any> {
    return this.httpClient.post<any>(this.endpoint + 'account/submit-id-with-selfie',details)
      .pipe(
        catchError(this.handleError<any>('Error occured'))
      );
  }


  getUserDiscountCodes(userID = ''): Observable<any> {

    return this.httpClient.get<any>(this.endpoint + 'discount-codes/' + userID)
      .pipe(
        tap(data => this.userDiscountCodes = data),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }

  getUserDiscountCode(userID = '',discountCodeID = ''): Observable<any> {

    return this.httpClient.get<any>(this.endpoint + 'discount-codes/' + userID + '/' + discountCodeID)
      .pipe(
        tap(data => this.userDiscountCodes = data),
        catchError(this.handleError<any>(`Get user id=${userID}`))
      );
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error('Error DevRenz', error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
  
}
