import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalService } from '../global.service';

export class Reservation {
  id: number = null;
  reservationVehicleID = '';
  reservationCustomerID = '';
  reservationStartDateTime = '';
  reservationStartTime = '';
  reservation12HEndTime = '';
  reservationEndDateTime = '';
  reservationType = '';
  reservationRate = 0;
  reservationPaymentType = '';
  reservationTotalCost = 0;
  reservationConvenienceFee = 0;
  reservationTotalPayable = 0;
  reservationBalance = 0;
  reservationPaidBalance = 0;
  reservationNumberOfDays = 1;
  reservationOrderID = '';
  reservationStatus = '';
  reservationStatusLabel = '';
  reviewText = '';
  reviewRating = '';
  reservationCancellationReason = '';
  reservationVehicleName = '';
  reservationVehicleImages: any = [];
  reservationVehicleOperator: any = {};
  reservationVehicleCustomer: any = {};
  vehicleUnavailableDates: [];
}

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  endpoint = '';

  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ){
    this.endpoint = this.globalService.endpoint + 'reservations';
  }


  getReservationsByVehicleID(vehicleID): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '/vehicle/' + vehicleID)
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  getReservationsByCustomerID(customerID,status = ''): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '/customer/' + customerID + '?status=' + status)
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  getReservationsStatsByCustomerID(customerID): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + '/customer/' + customerID + '/stats')
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<any>('Get reservation', []))
      );
  }
  getReservationsStatsByOperatorID(operatorID): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + '/operator/' + operatorID + '/stats')
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<any>('Get reservation', []))
      );
  }

  getReservationsByOperatorID(operatorID,status = ''): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '/operator/' + operatorID + '?status=' + status)
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  getReservationReviewsByOperatorID(operatorID): Observable<any> {
    return this.httpClient.get<any>(this.endpoint + '/operator/' + operatorID + '/reviews')
      .pipe(
        tap(response => console.log('Reservations retrieved!')),
        catchError(this.handleError<any>('Get reservation', []))
      );
  }

  getReservationsByOperatorPayable(operatorID): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '/operator/' + operatorID + '/payable')
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  getReservations(postsPerPage = 2): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '?posts_per_page=' + postsPerPage)
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  getReservationsByStatus(postsPerPage = 2): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '?posts_per_page=' + postsPerPage)
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  getReservationsNearby(customerID = '', $radius = '10'): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '/nearby?customer_id=' + customerID + '&radius=' + $radius)
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }
  getReservationsByOperator(userID = ''): Observable<Reservation[]> {
    return this.httpClient.get<Reservation[]>(this.endpoint + '/operator?user_id=' + userID + '&radius=')
      .pipe(
        tap(reservations => console.log('Reservations retrieved!')),
        catchError(this.handleError<Reservation[]>('Get reservation', []))
      );
  }

  createReservation(reservation: Reservation): Observable<any> {
    return this.httpClient.post<Reservation>(this.endpoint, JSON.stringify(reservation), this.httpOptions)
      .pipe(
        catchError(this.handleError<Reservation>('Error occured'))
      );
  }

  getReservation(id): Observable<Reservation> {
    return this.httpClient.get<Reservation>(this.endpoint + '/' + id )
      .pipe(
        tap(_ => console.log(`Reservation fetched: ${id}`)),
        catchError(this.handleError<Reservation>(`Get reservation id=${id}`))
      );
  }

  updateReservation(id, reservation: Reservation): Observable<any> {
    return this.httpClient.put(this.endpoint + '/' + id, JSON.stringify(reservation), this.httpOptions)
      .pipe(
        tap(_ => console.log(`Reservation updated: ${id}`)),
        catchError(this.handleError<Reservation[]>('Update reservation'))
      );
  }

  updateReservationReview(payload): Observable<any> {
    return this.httpClient.post(this.endpoint + '/review', JSON.stringify(payload), this.httpOptions)
      .pipe(
        tap(_ => console.log(`Updated Review`)),
        catchError(this.handleError<any>('Update reservation'))
      );
  }



  updateReservationStatus(id, reservation: Reservation): Observable<any> {
    return this.httpClient.put(this.endpoint + '/' + id + '/status', JSON.stringify(reservation), this.httpOptions)
      .pipe(
        tap(_ => console.log(`Reservation updated: ${id}`)),
        catchError(this.handleError<Reservation[]>('Update reservation'))
      );
  }

  deleteReservation(id): Observable<any> {
    return this.httpClient.delete(this.endpoint + '/' + id, this.httpOptions)
      .pipe(
        tap(_ => console.log(`Reservation deleted: ${id}`)),
        catchError(this.handleError<Reservation>('Delete reservation'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}

